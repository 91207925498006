import React from 'react'
import { graphql, Link } from 'gatsby'
import { theme } from '@chakra-ui/pro-theme'
import { ChakraProvider, extendTheme, Box, Heading, Button, Stack, Text, Input } from '@chakra-ui/react'
import { footer } from '../components/data'

import Header from '../components/header'
import '../components/layout.css'
import Seo from '../components/seo'
import Intregration from '../Intregration/Intregration'

const ConnectFaceFile = ({ data }) => {
  const connectData = data.contentfulIntegrationScreen
  const myTheme = extendTheme(
    {
      colors: { ...theme.colors, brand: theme.colors.blue }
    },
    theme
  )

  return (
    <ChakraProvider theme={myTheme}>
      <Header />
      <Seo title='Easily connect FaceFile with your favorite software through Zapier' />
      <Intregration connectData={connectData} />
      <Box
        backgroundColor='#2A4365'
        paddingTop={150}
        paddingLeft={50}
        paddingRight={50}
        paddingBottom={3}
      >
        <Heading
          fontSize='60px'
          color='#fff'
          fontWeight='700'
          textAlign='center'
          width={800}
          margin='0 auto'
        >
          {connectData.footer.title}
        </Heading>
        <Heading
          fontSize='18px'
          color='rgba(255, 255, 255, 0.64)'
          fontWeight='light'
          textAlign='center'
        >
          {connectData.footer.description}
        </Heading>
        <Box width={650} margin='0 auto' marginBottom={100}>
          <Input
            placeholder='Enter your email'
            display='inline-block !important'
            width={420}
            padding='10px 10px'
            marginRight={3}
          />
          <Button
            size='lg'
            bg='#3182CE'
            color='#fff'
            height='10'
            px='10'
            shadow='base'
            display='inline-block !important'
          >
            Try for free
          </Button>
        </Box>
        <Stack
          spacing={{
            base: '4',
            md: '5'
          }}
        >
          <Stack justify='center' direction='row' align='center' wrap='wrap'>
            <Box mr='8'>
              {' '}
              <img src={footer.logoWhite} width='97px' height={19} />
            </Box>
            <Text fontSize='sm' color='white' pr='8'>
              &copy; {new Date().getFullYear()} FaceFile, Inc.
            </Text>
            <Link to={connectData.footer.privacyButton.link}>
              <Text fontSize='sm' color='white' pr='8'>
                {connectData.footer.privacyButton.text}
              </Text>{' '}
            </Link>
            <Link to={connectData.footer.termsButton.link}>
              <Text fontSize='sm' color='white' pr='8'>
                {connectData.footer.termsButton.text}
              </Text>
            </Link>
            {/* <Link to={connectData.footer.blogButton.link}>
              <Text fontSize='sm' color='white'>
                {connectData.footer.blogButton.text}
              </Text>
            </Link> */}
          </Stack>
        </Stack>
      </Box>
    </ChakraProvider>
  )
}

export default ConnectFaceFile

export const query = graphql`
  query {
    contentfulIntegrationScreen {
      pageHero {
        title {
          raw
        }
        description
      }
      connectFaceFileWithSoftware {
        title
        description {
          description
        }
        connectWithSoftwares {
          softwareName
          description
          image {
            gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
          }
        }
      }
      workflowSection {
        title {
          raw
        }
        howFaceFileTitle
        image {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
        }
        workflowDefinedSteps {
          title
          description
          image {
            url
            gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
          }
        }
      }
      webhooksSection {
        title {
          raw
        }
        description
        learnMoreButton {
          text
        }
        image {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
        }
      }
      facefileApiSection {
        title {
          raw
        }
        description
        signUpButton {
          text
          link
        }
        image {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
        }
      }
      requestSection {
        title
        description
        requestButton {
          text
          link
        }
        image {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
        }
      }
      footer {
        title
        description
        privacyButton {
          text
          link
        }
        termsButton {
          text
          link
        }
        blogButton {
          text
          link
        }
      }
    }
  }
`
