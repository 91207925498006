import * as React from 'react'
import {
  Box,
  Heading,
  Img,
  Button,
  Hide,
  Stack,
  useColorModeValue as mode
} from '@chakra-ui/react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import HeroImage from '../images/IntregrationHero.png'

const Intregration = ({ connectData }) => {
  const options = {
    renderNode: {
      [MARKS.BOLD]: (_node, children) => (
        <b style={{ color: '#38B2AC' }}>{children}</b>
      ),
      [BLOCKS.HEADING_2]: (_node, children) => <span>{children}</span>
    }
  }

  return (
    <>
      <Box
        maxW={{
          base: '2xl',
          md: '8xl'
        }}
        mx='auto'
        px={{
          base: '6',
          md: '8'
        }}
        marginTop={20}
      >
        <Box flex='1' justifyContent='center' display='flex'>
          <Heading
            as='h1'
            fontSize='60px'
            color={mode('black.600', 'black.300')}
            fontWeight='bold'
            textAlign='center'
            paddingBottom='10px'
            width={800}
            sx={{
              b: {
                color: 'teal.400'
              }
            }}
          >
            {renderRichText(connectData.pageHero.title, options)}
          </Heading>
        </Box>
        <Box flex='1' justifyContent='center' display='flex'>
          <Heading
            as='h1'
            fontSize='24px'
            color='#4A5568'
            textAlign='center'
            lineHeight='32px'
            fontWeight='light'
            width={760}
            mt='4'
          >
            {connectData.pageHero.description}
          </Heading>
        </Box>
        <Box marginTop='-80px'>
          <Img src={HeroImage} />
        </Box>
        <Box
          backgroundColor='#F7FAFC'
          padding='50px'
          mt={{ lg: '20' }}
          borderRadius={16}
        >
          <Heading as='h1' fontSize='48px' className='software-connect-hd'>
            {connectData.connectFaceFileWithSoftware.title}
          </Heading>
          <Heading
            fontSize='24px'
            fontWeight='light'
            lineHeight='32px'
            paddingBottom='30px'
          >
            {connectData.connectFaceFileWithSoftware.description.description}
          </Heading>
          <Box
            marginTop='20px'
            display='flex'
            justifyContent='space-between'
            flexWrap='wrap'
            className='software-connect-item'
          >
            {connectData.connectFaceFileWithSoftware.connectWithSoftwares.map(
              software => (
                <Box
                  width='30%'
                  marginBottom='50px'
                  key={software.softwareName}
                  borderRadius='50px !important'
                  className='item-box-sm'
                >
                  <GatsbyImage
                    image={getImage(software.image.gatsbyImageData)}
                    alt={software.softwareName || ''}
                    style={{
                      borderTopRightRadius: 8,
                      borderTopLeftRadius: 8,
                      width: '100%'
                    }}
                  />
                  <Box
                    backgroundColor='#fff'
                    padding='10px 20px'
                    borderBottomLeftRadius={8}
                    borderBottomRightRadius={8}
                  >
                    <Heading
                      color='#1A202C'
                      fontSize='16px'
                      fontWeight='medium'
                      lineHeight='50px'
                    >
                      {software.softwareName}
                    </Heading>
                    <Heading
                      color='#1A202C'
                      fontSize='16px'
                      fontWeight='light'
                      lineHeight='24px'
                      paddingBottom='20px'
                    >
                      {software.description}
                    </Heading>
                  </Box>
                </Box>
              )
            )}
          </Box>
        </Box>
        <Box padding='100px 0px'>
          <Heading
            textAlign='center'
            fontSize='48px'
            lineHeight='48px'
            sx={{
              b: {
                color: 'teal.400'
              }
            }}
            width={620}
            margin='0 auto'
          >
            {renderRichText(connectData.workflowSection.title, options)}
          </Heading>
        </Box>
        <Box>
          <Box
            maxW={{
              base: '2xl',
              md: '7xl'
            }}
            mx='auto'
            px={{
              base: '6',
              md: '8'
            }}
          >
            <Stack
              direction={{
                base: 'column',
                lg: 'row'
              }}
              spacing={{
                base: '3rem',
                lg: '2rem'
              }}
              mt='8'
              justify='space-between'
            >
              <Box
                flex='1'
                maxW={{
                  lg: '520px'
                }}
              >
                <Heading
                  as='h2'
                  size='3xl'
                  color={mode('black.600', 'black.300')}
                  fontWeight='bold'
                  letterSpacing='tight'
                  fontSize='40px'
                  paddingTop='100px'
                >
                  {connectData.workflowSection.howFaceFileTitle}
                </Heading>
                {connectData.workflowSection.workflowDefinedSteps.map(step => (
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    marginTop='30px'
                    key={step.title}
                  >
                    <Box marginRight='20px'>
                      <Img
                        src={step.image.url}
                        width='50px'
                        marginTop='10px'
                      />
                    </Box>
                    <Box>
                      <Heading
                        fontSize='18px'
                        lineHeight='28px'
                        fontWeight='medium'
                      >
                        {step.title}
                      </Heading>
                      <Heading
                        fontSize='16px'
                        lineHeight='24px'
                        fontWeight='medium'
                        color='#4A5568'
                      >
                        {step.description}
                      </Heading>
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box
                pos='relative'
                w={{
                  base: 'full',
                  lg: '50%'
                }}
                h={{
                  base: 'auto',
                  lg: '100%'
                }}
              >
                <GatsbyImage
                  image={getImage(connectData.workflowSection.image.gatsbyImageData)}
                  alt={connectData.workflowSection.howFaceFileTitle || ''}
                />
                <Box pos='absolute' w='100%' h='100%' top='-4' left='-4' />
              </Box>
            </Stack>
          </Box>
        </Box>
        <Box>
          <Box
            maxW={{
              base: '2xl',
              md: '7xl'
            }}
            mx='auto'
            px={{
              base: '6',
              md: '8'
            }}
            backgroundColor='rgba(230, 255, 250, 0.6)'
            padding='20px'
            marginTop='20px'
            marginBottom='80px'
            borderRadius='16px'
          >
            <Stack
              direction={{
                base: 'column',
                lg: 'row'
              }}
              spacing={{
                base: '3rem',
                lg: '2rem'
              }}
              my='8'
              justify='space-between'
            >
              <Box
                pos='relative'
                w={{
                  base: 'full',
                  lg: '50%'
                }}
                h={{
                  base: 'auto',
                  lg: '100%'
                }}
              >
                <GatsbyImage
                  image={getImage(connectData.webhooksSection.image.gatsbyImageData)}
                  alt={connectData.webhooksSection.description || ''}
                />
                <Box pos='absolute' w='100%' h='100%' top='-4' left='-4' />
              </Box>
              <Box
                flex='1'
                maxW={{
                  lg: '520px'
                }}
                alignSelf='center'
              >
                <Heading
                  as='h2'
                  size='3xl'
                  fontSize='48px'
                  lineHeight='48px'
                  sx={{
                    b: {
                      color: 'teal.400'
                    }
                  }}
                >
                  {renderRichText(connectData.webhooksSection.title, options)}
                </Heading>
                <Heading
                  fontSize='20px'
                  lineHeight='28px'
                  fontWeight='light'
                  paddingTop='20px'
                >
                  {connectData.webhooksSection.description}
                </Heading>
                <Hide below='lg'>
                  <Stack
                    direction={{
                      base: 'column',
                      md: 'row'
                    }}
                    spacing='4'
                    mt='8'
                  >
                    <Link isExternal>
                      <Button
                        size='lg'
                        colorScheme='blue'
                        backgroundColor='blue.500'
                        color='#fff'
                        rightIcon={<FontAwesomeIcon icon={faArrowRight} size='md' />}
                        height='14'
                        px='10'
                        shadow='base'
                      >
                        {connectData.webhooksSection.learnMoreButton.text}
                      </Button>
                    </Link>
                  </Stack>
                </Hide>
              </Box>
            </Stack>
          </Box>
        </Box>
        <Box>
          <Box
            maxW={{
              base: '2xl',
              md: '7xl'
            }}
            mx='auto'
            px={{
              base: '6',
              md: '8'
            }}
            backgroundColor='rgba(250, 245, 255, 0.6)'
            padding='20px'
            marginTop='20px'
            borderRadius='16px'
          >
            <Stack
              direction={{
                base: 'column',
                lg: 'row'
              }}
              spacing={{
                base: '3rem',
                lg: '2rem'
              }}
              mt='8'
              justify='space-between'
              padding='0px 0px 0px 40px'
            >
              <Box
                flex='1'
                maxW={{
                  lg: '520px'
                }}
              >
                <Heading
                  as='h2'
                  size='3xl'
                  fontSize='45px'
                  lineHeight='48px'
                  paddingTop='80px'
                  sx={{
                    b: {
                      color: 'teal.400'
                    }
                  }}
                >
                  {renderRichText(
                    connectData.facefileApiSection.title,
                    options
                  )}
                </Heading>
                <Heading
                  fontSize='20px'
                  lineHeight='28px'
                  fontWeight='light'
                  paddingTop='20px'
                >
                  {connectData.facefileApiSection.description}
                </Heading>
                <Hide below='lg'>
                  <>
                    {' '}
                    <Stack
                      direction={{
                        base: 'column',
                        md: 'row'
                      }}
                      spacing='4'
                      mt='8'
                    >
                      <Link
                        isExternal
                        to={connectData.facefileApiSection.signUpButton.link}
                      >
                        <Button
                          size='lg'
                          bg='#3182CE'
                          color='#fff'
                          height='14'
                          px='10'
                          shadow='base'
                        >
                          {connectData.facefileApiSection.signUpButton.text}
                        </Button>
                      </Link>
                    </Stack>
                  </>
                </Hide>
              </Box>

              <Box
                pos='relative'
                w={{
                  base: 'full',
                  lg: '50%'
                }}
                h={{
                  base: 'auto',
                  lg: '100%'
                }}
              >
                <GatsbyImage
                  image={getImage(connectData.facefileApiSection.image.gatsbyImageData)}
                  alt={connectData.facefileApiSection.description || ''}
                />
                <Box pos='absolute' w='100%' h='100%' top='-4' left='-4' />
              </Box>
            </Stack>
          </Box>
        </Box>
        <Box paddingTop='120px'>
          <GatsbyImage
            image={getImage(connectData.requestSection.image.gatsbyImageData)}
            alt={connectData.requestSection.title || ''}
          />
        </Box>
        <Box marginBottom='120px'>
          <Heading
            fontSize='60px'
            paddingTop='20px'
            color='#1A202C'
            textAlign='center'
          >
            {connectData.requestSection.title}
          </Heading>
          <Heading
            fontSize='18px'
            color='#4A5568'
            textAlign='center'
            lineHeight='28px'
            fontWeight='light'
          >
            {connectData.requestSection.description}
          </Heading>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            paddingTop='30px'
          >
            <Link
              isExternal
              to={connectData.requestSection.requestButton.link}
            >
              <Button
                size='lg'
                bg='#3182CE'
                color='#fff'
                height='14'
                px='10'
                shadow='base'
              >
                {connectData.requestSection.requestButton.text}
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  )
}
export default Intregration
